<template lang="html">
  <section class="rrss-shared container">
    <div class="rrss">
      <span title="Compartir en redes sociales" alt="Compartir en redes sociales">{{$t('web.blog.share')}}</span>
      <a rel="noopener" target="_blank"  class="facebook-icon" :href="'http://www.facebook.com/sharer.php?u='+dataUrl+'&t='+dataTitle"></a>
      <a rel="noopener" target="_blank" class="twitter-icon" :href="'http://twitter.com/intent/tweet?text='+dataTitle+'&url='+dataUrl"></a>
      <a rel="noopener" target="_blank" class="linkedin-icon" :href="'https://www.linkedin.com/shareArticle?mini=true&url='+dataUrl+'/&title='+dataTitle+''"></a>
      <a :href="'https://telegram.me/share/url?url='+dataUrl+ '&text=' + dataTitle" title="Compartir en Telegram" alt="Compartir en Telegram" target="_blank" class="telegram-icon"></a>
    
    </div>

  </section>
</template>

<script lang="js">
  export default {
    name: 'rrss-shared',
    props: ['dataTitle', 'dataUrl', 'dataImage'],
    mounted() {

    },
    data() {
      return {
        url: location.href,
      }
    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";
  @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

  .rrss-shared {
    width: 100%;

    .rrss {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      span {
        color: $blueLight;
        font-weight: 700;
      }

      a {
        background: $blueLight;
        border-radius: 50%;
        margin-left: 10px;
        width: 27px;
        height: 27px;
        text-align: center;
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;
        border: 1px solid $blueLight;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        padding-top: 2px;

        &.facebook-icon {
          background-image: url('/img/icons/facebook-white.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 10px;

          &:hover {
            border: 1px solid $blueLight;
            background-color: white;
            background-image: url('/img/icons/facebook-blue.svg');
          }
        }

        &.twitter-icon {
          background-image: url('/img/icons/twitter-white.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 15px;

          &:hover {
            border: 1px solid $blueLight;
            background-color: white;
            background-image: url('/img/icons/twitter-blue.svg');
          }
        }

        &.linkedin-icon {
          background-image: url('/img/icons/linkedin-white.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 15px;

          &:hover {
            border: 1px solid $blueLight;
            background-color: white;
            background-image: url('/img/icons/linkedin-blue.svg');
          }
        }

        &.telegram-icon {
          background-image: url('/img/icons/telegram-white.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 17px;

          &:hover {
            border: 1px solid $blueLight;
            background-color: white;
            background-image: url('/img/icons/telegram-blue.svg');
          }
        }

      }
    }
  }

  @media screen and (max-width:570px) {
    .page-blog .content .share-goBack .rrss-shared {
      width: 100%;
      padding-left: 0;
    }

    .rrss-shared .rrss {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }

  @media screen and (max-width:380px) {
    .rrss-shared .rrss a {
      width: 24px !important;
      height: 24px !important;
    }
  }
</style>