<template lang="html">

  <section class="page-blog" v-if="entrySelected">
    <section class="head">
      <div class="mask-title">
        <div class="container">
          <img :src="entrySelected.image.url" alt="imagen blog">
          <div>

            <h1 class="title-extra-big -whiteC">{{ entrySelected.name }}</h1>
            <!-- <h1>{{store.lang}}</h1> -->

            <p class="date -whiteC" data-v-19788c2e="">{{ currentDateTime(entrySelected.publish_date) }}</p>
          </div>
        </div>
      </div>
    </section>
   

    <section class="content container">
      <div class="share-goBack">
        <router-link :to="{ name: 'blog' }" class="btn-blue">BLOG</router-link>

        <rrssShared :dataTitle="entrySelected.name" :dataUrl="url" :dataImage="entrySelected.image.url"></rrssShared>
      </div>
      <p class="title-medium -blueDarkC">{{ entrySelected.header }}</p>
      <div class="description">
        <EditorJs :object="entrySelected.body" />
      </div>
    </section>
    <section class="related-content" v-if="relatedEntries">


      <div class="container">
        <p class="title-semi-medium -blueC">{{$t('web.blog.other')}}</p>
        <div class="container grid-3">
          <router-link :to="{
            name: 'blog-page',
            params: {
              lang:$i18n.locale,
              slug: entryRelated.slug,
            }
          }" v-for="(entryRelated, key ) in Object.values(relatedEntries).slice(0, 3)" :key="key" class="grid entry-view">
            <div class="img-container">
              <img :src="entryRelated.image.url" alt="imagen blog">

            </div>
            <p class="title-semi-medium uppercase">
              {{ entryRelated.name }}
            </p>
            <p class="date">{{ currentDateTime(entryRelated.publish_date) }}</p>
          </router-link>
        </div>
      </div>
    </section>
  </section>

</template>

<script lang="js">
  import rrssShared from "@/components/rrss-shared.vue";
  import { contentStore } from '@/stores/contents';
  import EditorJs from "@/components/editorJs.vue"
  const store = contentStore();
  import moment from 'moment'
  export default {
    name: 'page-blog',
    props: ['lang', 'slug'],
    components: {
      rrssShared,
      EditorJs
    },
    setup() {
      return {
        loadEntry: store.loadEntry,
        getEntryByCategoryBySLug: store.getEntryByCategoryBySLug,
        store
      }
    },
    mounted() {
      this.getEntryBySlug();
      store.loadEntries(
        {
          slug: this.slug
        }
      )

    },
    data() {
      return {
        url: window.location,
        entrySelected: null,
      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale(this.lang).format('LL')
      },
      getEntryBySlug() {
        let t = this;
        store.loadEntry(
          {
            slug: this.slug,
          }
        ).then(function (result) {
          t.entrySelected = result
          document.title = t.entrySelected.name
          document.head.querySelector("[name=description]").content = t.entrySelected.header
          document.head.querySelector("[name=title]").content = t.entrySelected.name
          //

          document.head.querySelector("[property='og:title']").content = t.entrySelected.name;
          document.head.querySelector("[property='og:image']").content =  t.entrySelected.image.url;
          document.head.querySelector("[property='og:description']").content = t.entrySelected.header
          //

          document.head.querySelector("[name='twitter:title']").content = t.entrySelected.name;
          document.head.querySelector("[name='twitter:description']").content = t.entrySelected.header;
          document.head.querySelector("[name='twitter:image']").content = t.entrySelected.image.url;
        });
      }
    },
    computed: {
      relatedEntries() {
        return this.getEntryByCategoryBySLug(this.slug, this.slug, this.page);
      },
      loc(){
        return  location.pathname
      }
    },
    watch: {
      "store.lang"() {
        this.$router.push({ name: "blog" })
      },
      slug() {
        this.getEntryBySlug();
      },
    }
  }


</script>

<style scoped lang="scss">
  @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

  .page-blog .head .mask-title {
    width: 100%;
    height: 100%;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .page-blog .head img {
    height: 100%;
    width: 65%;
    max-width: 633px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
  }

  .-whiteC {
    color: #1E2F3F;
  }

  .page-blog .head .mask-title .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    flex-direction: row-reverse;
    padding: 50px 20px;
    padding-bottom: 50px;
    margin-top: 35px;
  }
  .mask-title .container div{
    width:40%;
  }
  
  @media screen and (max-width:895px) {
    .page-blog .head .mask-title .container div{
    width:100%
  }
    .page-blog .head .mask-title .container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      flex-direction: column-reverse;
      padding: 50px 20px;
    padding-bottom: 71px;
    margin-top: 80px;
    }
    .page-blog .head img {
    height: 100%;
    /* width: 81%; */
    /* max-width: 90%; */
    /* min-height: 631px; */
    margin-top: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center center;
    object-position: center center;
}
  }
</style>